import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
const initialState = {
	user: {},
	token: localStorage.getItem("token"),
	userLoaded: false
};

const userSlice = createSlice({
	name: 'user',
	initialState: initialState,
	reducers: {
	  loginSuccess(state, action) {
		state.token = action.payload.data.access_token
		const userData = jwtDecode(action.payload.data.access_token)
		state.user = userData
		state.userLoaded = true
	  },
	  logoutUser(state, action) {
		state.user=false
		state.token=null
		localStorage.removeItem("token")
	  },
	  loadUser(state, action) {
		const token = state.token;
		if (token) {
			const userData = jwtDecode(token);
			const currentTime = new Date()/1000
			const tokenExpirationTime = userData.exp
			// token expiration l
		// state.user=action.payload.data
		// state.token=action.payload.data.tokenogic inside if
			if (tokenExpirationTime < currentTime) {
				state.user = false
				state.token = null
				localStorage.removeItem("token")
				window.location.href = "/"
			} else {
				state.token = token
				state.user = {
					email: userData.email,
					userId: userData._id,
					isAdmin: userData.isAdmin
				}
			}

		} else {
			state.user = false
			state.token = null
			state.userLoaded = true
			console.log("running loadUser else")
		};
	},
	},
  });
  
  export const userActions = userSlice.actions;
  
  export default userSlice;
  


// export const user = (state = initialState, action) => {
// 	switch (action.type) {
// 	case "LOGIN_SUCCESS":
// 		return { ...state, user: action.data, token: action.data.token };
// 	case "ISLOADING":
// 		return { ...state, isLoading: action.data };
// 	default:
// 		return state;
// 	}
// };
