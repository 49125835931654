import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  cartItems: [],
  // totalQuantity: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    clearCart(state, action) {
      state.totalQuantity = 0;
      state.cartItems = [];
    },
    removeCartItem(state, action) {
      const {id} = action.payload.data;
      state.cartItems = state.cartItems.filter((item) => item._id !== id);
    },
    addItemToCart(state, action) {
      const newItem = action.payload.data;
      const existingItem = state.cartItems.find((item) => item._id === newItem._id);
      console.log("existingItem",existingItem)
      if (!existingItem) { 
        state.cartItems.push({
          _id: newItem._id,
          Price: newItem.Price,
          quantity: 1,
          totalPrice: newItem.Price,
          name: newItem.Name,
        });
      }
    },
    incCartItemQuantity(state, action) {
      const newItem = action.payload.data;
      console.log('newItem',newItem);
      const existingItem = state.cartItems.find((item) => item._id === newItem.id);
     
        if(newItem.quantity === ""){
          existingItem.quantity = 0
          existingItem.totalPrice = existingItem.Price 
        }
        else{
          newItem.quantity ? (existingItem.quantity = Number(newItem.quantity)) : (existingItem.quantity++);
          newItem.quantity ? (existingItem.totalPrice = existingItem.Price * Number(newItem.quantity)) : (existingItem.totalPrice = existingItem.totalPrice + newItem.price);
        }
        
    },
    decCartItemQuantity(state, action) {
      const { id, price } = action.payload.data;
      const existingItem = state.cartItems.find((item) => item._id === id);
      if (existingItem.quantity === 1) {
        state.cartItems = state.cartItems.filter((item) => item._id !== id);
      } else {
        existingItem.quantity--;
        existingItem.totalPrice = existingItem.totalPrice - price;
      }
    },
  },
});

export const cartActions = cartSlice.actions;

export default cartSlice;
