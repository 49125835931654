import { toast } from "react-toastify";
import { handleResponse } from "../../api-client/handleResponse";
import { BASE_URL, setRequestConfigs,} from "../../api-client/setRequestConfigs";
import { alertActions } from "../reducers/alertReducer";
import axios from "axios";
import { toast_config } from "../../utils/constants";
import { bookingAction } from "../reducers/bookingReducer";


const get_all_booking = () => {
  return async(dispatch) => {
      dispatch(alertActions.IsLoading({ data: true }));
      const serverRequest = setRequestConfigs();
      let response;
      try {
        response = await serverRequest.get("http://localhost:5000/api/v1/bookings/show-bookings")
        if (response.data.code === 200) {
          dispatch(alertActions.IsLoading({ data: false }));
          dispatch(bookingAction.allBookings({data : response.data.data}))
          console.log("data",response.data)
          
        } else {
          dispatch(alertActions.IsLoading({ data: false }));
          toast.error(response.data.message,toast_config)
          handleResponse(response?.data);
        }
      } catch (error) {
        toast.error(error.response?.data?.message,toast_config)
        handleResponse(error.response?.data);
        console.log("error",error);
      }
  };
};

const confirm_booking = (data) => {
  return async (dispatch) => {
    console.log("Data in action form: ", data);
 
    dispatch(alertActions.IsLoading({ data: true }));
    const serverRequest = setRequestConfigs();
    let response;
    try {
      response = await serverRequest.put(`http://localhost:5000/api/v1/bookings/confirm-bookings/${data.id}`,data);
      if (response.data.code === 200) {
        dispatch(alertActions.IsLoading({ data: false }));
        window.location.href = "/admin/dashboard/booking"
      } else {
        dispatch(alertActions.IsLoading({ data: false }));
        toast.error(response.data.message, toast_config);
        handleResponse(response?.data);
      }
    } catch (error) {
      console.log("error", error);
      handleResponse(error.response?.data);
      toast.error(error.response?.data?.message, toast_config);
    }
   };
};

const edit_booking = (token, BookingData) => {
  let id = BookingData.ID
  console.log("id... in edit booking action form", id);
  console.log("BookingData... in edit booking action form", BookingData);
  return async (dispatch) => {
    dispatch(alertActions.IsLoading({ data: true }));
    let response;
    try {
      response = await axios.put(
         BASE_URL + `/bookings/update-bookings/${id}`,
        BookingData, // Pass BookingData as payload
        {
          headers: {
            "Content-Type": "application/json", // Change content type if required
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.code === 200) {
        dispatch(alertActions.IsLoading({ data: false }));
        toast.success(response?.data?.message, toast_config);
        //window.location.href = "/admin/dashboard/booking"
      } else {
        dispatch(alertActions.IsLoading({ data: false }));
        toast.error(response.data.message, toast_config);
        handleResponse(response?.data);
      }
    } catch (error) {
      console.log("error", error);
      dispatch(alertActions.IsLoading({ data: false }));
      handleResponse(error.response?.data);
      toast.error(error.response?.data?.message, toast_config);
    }
  };
};

export { get_all_booking, edit_booking, confirm_booking };

