import { alertActions } from "../context/reducers/alertReducer"
import { userActions } from "../context/reducers/userReducer"
import {store} from "../context/store"
export const logout = () => {
    console.log("user logged out")
    store.dispatch(alertActions.IsLoading({ data: false }))
    store.dispatch(userActions.logoutUser())
    window.location.href = "/"
}

export function handleResponse(response) {
    console.log("inside handle response", response)
    if (response?.code === 403) {
        setTimeout(() => {
            logout(response);
        }, 1000);
    } else if (response?.data?.code === 405) {
        console.log('unauthorized user');
        setTimeout(() => {
            logout(response);
        }, 1000);
    } else if (response?.data?.code === 406) {
        setTimeout(() => {
            logout(response);
        }, 1000);
    }else{
        return response
    }
}


