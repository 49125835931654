import { combineReducers } from "redux";
import alertSlice from "./alertReducer";
import productSlice from "./productReducer";
import userSlice from "./userReducer";
import cartSlice from "./cartReducer";
import orderSlice from "./orderReducer";
import vehicalSlice from "./vehicalReducer";
import BookingSlice from "./bookingReducer"

const rootReducer = combineReducers({ 
	userReducer :  userSlice.reducer,
	productReducer : productSlice.reducer,
	alertReducer : alertSlice.reducer,
	cartReducer : cartSlice.reducer,
	orderReducer : orderSlice.reducer,
	vehicalReducer : vehicalSlice.reducer,
	bookingReducer : BookingSlice.reducer,
});

export default rootReducer;