import React, { useState, useEffect } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {  edit_booking } from '../../../../context/actions/bookingAction';
import {} from '../../../../context/actions/userAction'
import './Booking.css';

const validationBookingSchema = Yup.object({
  fullname: Yup.string().required("Full name is required").max(50), 
  total_passengers: Yup.number().required("Total passengers is required"),
  pickup_address: Yup.string().required("Pickup address is required"), 
  dropoff_address: Yup.string().required("Dropoff address is required"), 
  email: Yup.string().email().required("email is required"), 
  phone_no: Yup.number().required("phone no. is required"), 
  owner_id: Yup.number().required("required owner id"),
  vehical_id: Yup.number().required("required vehical id"), 
  vehical_type: Yup.string().required("required vehical type"),
});

const handleKeyDown = (evt) => {
  if (evt.key === 'Backspace') {
    return;
  }
  if (!/^\d$/.test(evt.key)) {
    evt.preventDefault();
  }
};

const EditBooking = (props) => {
  const OwnerData = useSelector((state) => state.userReducer.userData);
  const [bookingInput, setBookingInput] = useState({
    fullname: props.selectedBooking ? props.selectedBooking.fullname : '',
    total_passengers: props.selectedBooking ? props.selectedBooking.total_passengers: '',
    pickup_address: props.selectedBooking ? props.selectedBooking.pickup_address : '',
    dropoff_address: props.selectedBooking ? props.selectedBooking.dropoff_address : '',
    email: props.selectedBooking ? props.selectedBooking.email : '',
    phone_no: props.selectedBooking ? props.selectedBooking.phone_no : '',
    owner_id: props.selectedBooking ? props.selectedBooking.owner_id : '',
    vehical_id: props.selectedBooking ? props.selectedBooking.vehical_id : '',
    vehical_type: props.selectedBooking ? props.selectedBooking.vehical_type : '',
  });

  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBookingInput({ ...bookingInput, [name]: value });
  };

  function handleSubmit(event) {
    event.preventDefault();
    const ID = props.selectedBooking.id
    console.log("id in edit form",ID)
    console.log("data in edit form",bookingInput)
    dispatch(edit_booking({ID:ID,BookingData:bookingInput}))
  }

  const initialValues = {
    fullname: bookingInput.fullname,
    total_passengers: bookingInput.total_passengers,
    pickup_address:bookingInput.pickup_address,
    dropoff_address: bookingInput.dropoff_address,
    email: bookingInput.email,
    phone_no: bookingInput.phone_no,
    owner_id: bookingInput.owner_id,
    vehical_id: bookingInput.vehical_id,
    vehical_type: bookingInput.vehical_type, 
};

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationBookingSchema,
    onSubmit: async (values) => {
      console.log("Form values:", values);
      console.log('Formik Errors:', formik.errors);
      await dispatch(edit_booking(values));
    },
  });

  return (
    <div id="content" className="formstyle">
      <form autoComplete="off" onSubmit={handleSubmit}>
        <h2>Edit Booking</h2>
        <>
        <TextField
            id="fullname"
            name="fullname"
            label="Full Name"
            fullWidth
            size="small"
            value={formik.values.fullname}
            sx={{ mb: 3 }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
        />
        {formik.touched.fullname && formik.errors.fullname ? (
            <div className="error">{formik.errors.fullname}</div>
        ) : null}
         </>

        <>
        <TextField
          id="total_passengers"
          name="total_passengers"
          label="Total Passengers"
          onKeyDown={handleKeyDown}
          fullWidth
          size="small"
          value={formik.values.total_passengers}
          sx={{ mb: 3 }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.total_passengers && formik.errors.total_passengers ? (
          <div className="error">{formik.errors.total_passengers}</div>
         ) : null}
         </>

        <>
        <TextField
          id="pickup_address"
          name="pickup_address"
          label="Pickup Address"
          fullWidth
          size="small"
          value={formik.values.pickup_address}
          sx={{ mb: 3 }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.pickup_address && formik.errors.pickup_address ? (
          <div className="error">{formik.errors.pickup_address}</div>
         ) : null}
         </>

        <>
        <TextField
          id="dropoff_address"
          name="dropoff_address"
          label="Dropoff Address"
          fullWidth
          size="small"
          value={formik.values.dropoff_address}
          sx={{ mb: 3 }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.dropoff_address && formik.errors.dropoff_address ? (
          <div className="error">{formik.errors.dropoff_address}</div>
         ) : null}
         </>
        
        <>
        <TextField
          id="phone_no"
          name="phone_no"
          label="Phone No"
          onKeyDown={handleKeyDown}
          fullWidth
          size="small"
          value={formik.values.phone_no}
          sx={{ mb: 3 }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.phone_no && formik.errors.phone_no ? (
          <div className="error">{formik.errors.phone_no}</div>
         ) : null}
         </>

        <>
        <TextField
          id="email"
          name="email"
          label="Email"
          fullWidth
          size="small"
          value={formik.values.email}
          sx={{ mb: 3 }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className="error">{formik.errors.email}</div>
         ) : null}
         </>

        {/* <FormControl fullWidth>
          <InputLabel id="owner_id">Owner ID</InputLabel>
          <>
          <Select
              id="owner_id"
              name="owner_id"
              onKeyDown={handleKeyDown}
              label="Owner ID"
              fullWidth
              size="small"
              value={formik.values.owner_id}
              sx={{ mb: 3 }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
          >
              {OwnerData && OwnerData.map((data) => ( // Add null check for OwnerData
                  <MenuItem key={data.id} value={data.id}>{data.email}</MenuItem>
              ))}
          </Select>
          </>
        </FormControl> */}
        <TextField
          id="owner_id"
          name="owner_id"
          label="owner_id "
          onKeyDown={handleKeyDown}
          fullWidth
          size="small"
          value={formik.values.owner_id}
          sx={{ mb: 3 }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.owner_id && formik.errors.owner_id ? (
          <div className="error">{formik.errors.owner_id}</div>
         ) : null}
         

        {/* <FormControl fullWidth>
          <InputLabel id="vehical_id">Vehicle ID</InputLabel>
          <>
          <Select
            id="vehical_id"
            name="vehical_id"
            onKeyDown={handleKeyDown}
            label="Vehicle ID"
            fullWidth
            size="small"
            value={formik.values.vehical_id}
            sx={{ mb: 3 }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
          {formik.touched.vehical_id && formik.errors.vehical_id ? (
          <div className="error">{formik.errors.vehical_id}</div>
         ) : null}
          </Select>
          </>
        </FormControl> */}

        <TextField
          id="vehical_id"
          name="vehical_id"
          label="vehical_id "
          onKeyDown={handleKeyDown}
          fullWidth
          size="small"
          value={formik.values.vehical_id}
          sx={{ mb: 3 }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.vehical_id && formik.errors.vehical_id ? (
          <div className="error">{formik.errors.vehical_id}</div>
         ) : null}

        <>
        <TextField
          id="vehical_type"
          name="vehical_type"
          label="Vehicle Type"
          fullWidth
          size="small"
          value={formik.values.vehical_type}
          sx={{ mb: 3 }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.vehical_type && formik.errors.vehical_type ? (
          <div className="error">{formik.errors.vehical_type}</div>
         ) : null}
         </>

      
        <Button variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button variant="outlined" color="secondary" type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
};

export default EditBooking;