import "./App.css";
// import { useEffect } from "react";
import routers from "./routers";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import ImageUploader from 'react-image-upload'
import 'react-image-upload/dist/index.css'
import { toast_config } from "./utils/constants";
import { Fragment, Suspense } from "react";
import Box from '@mui/material/Box';
// import { userActions } from "./context/reducers/userReducer";
// import { useDispatch } from "react-redux";
function App() {
	//token check logic
	// const dispatch = useDispatch()
	// useEffect(() => {
	// 	console.log("running useEffect")
	// 	dispatch(userActions.loadUser())
	// }, [])
	return (
		<Fragment>
			
			<Suspense fallback={<p>Loading...</p>}>
				
					<RouterProvider router={routers} />

					<ToastContainer {...toast_config} />
				
			</Suspense>
		</Fragment>
	);
}

export default App;