import React, { useEffect, useState } from "react";
import Vehical from "./Vehical";
import "./VehicalList.css";
import { useDispatch, useSelector } from "react-redux";
import { get_all_vehical } from "../../../context/actions/vehicalAction";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const VehicalList = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.userReducer.token);
  const all_vehical = useSelector((state) => state.vehicalReducer.vehicals);
  const [selectedList,setSelectedList] = useState([])

  useEffect(() => {
    dispatch(get_all_vehical(token));
  }, [dispatch, token]);

  useEffect(() => {
    setSelectedList(all_vehical.filter((obj) => obj.type === 'car'));
  }, [all_vehical]);

  const handleSort =(eventKey)=>{
    setSelectedList(all_vehical.filter((obj)=> obj.type == eventKey))
  }

  return (
    <>
      <div className="tab_wrapper">
        <Tabs
          defaultActiveKey="car"
          id="vehical-list"
          className="mb-3"
          fill
          onSelect={handleSort}
        >
          <Tab eventKey="car" title="Cars"></Tab>
          <Tab eventKey="bus" title="Bus"></Tab>
          <Tab eventKey="other" title="Groom/Brides"></Tab>
        </Tabs>
      </div>
      <div className="vehical_container">
        {selectedList.length > 0 ? (
          selectedList.map((vehical) => <Vehical vehicalList={vehical} />)
        ) : (
          <h3>No vehical found</h3>
        )}
      </div>
    </>
  );
};

export default VehicalList;
