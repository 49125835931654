/* eslint-disable react/prop-types */
import React from "react";
// import GoogleAuthLogin from "../../../modules/authentication/social/GoogleAuthLogin";
// import Footer from "../footer/Footer";
// import Header from "../header/Header";

const PublicLayout = ({ children }) => {
	return (
		<>
			{/* <Header />  */}
			{children}
			{/* <GoogleAuthLogin/> */}
			{/* <Footer /> */}
		</> 
	);
};

export default PublicLayout; 