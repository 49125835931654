import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
const initialState = {
	vehicals: []
};

const vehicalSlice = createSlice({
	name: 'vehical',
	initialState: initialState,
	reducers: {
	  allVehical(state, action) {
		let {cars,buses,OtherVehical} = action.payload.data
        cars.map((obj)=> obj.type = "car")
		buses.map((obj)=> obj.type = "bus")
		OtherVehical.map((obj)=> obj.type = "other")
		const vehicals = [...cars,...buses,...OtherVehical]
		console.log("vksdkjfhds",vehicals)
		state.vehicals = vehicals
	  },
	},
  });
  
  export const vehicalAction = vehicalSlice.actions;
  
  export default vehicalSlice;
