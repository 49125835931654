import publicRoutes from "./PublicRoutes";
import AdminRoutes from "./AdminRoutes";
import { createBrowserRouter} from "react-router-dom";

const routers = createBrowserRouter([
	...publicRoutes,
	...AdminRoutes
]);

export default routers;	 

