import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import EditBooking from './EditBooking';
import { Select, MenuItem } from '@mui/material'; // Import Select and MenuItem from @mui/material
import { get_all_booking, confirm_booking } from '../../../../context/actions/bookingAction';
import './Booking.css';



const Booking = () => {
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const Bookings = useSelector((state) => state.bookingReducer.bookings);

  useEffect(() => {
    dispatch(get_all_booking());
  }, [dispatch]);

  const handleEdit = (booking) => {
    console.log('booking in handle edit booking page', booking);
    setSelectedBooking(booking);
    setIsEditing(true);
  };
  const handleConfirmChange = async (event, rowData) => {
    if (event !== undefined && event !== null) {
      const confirmAction = async () => {
        await dispatch(confirm_booking(rowData)); 
        Swal.fire({
          icon: 'success', 
          title: event ? 'Booking is confirmed' : 'Booking is canceled',
        });
      };
  
      const confirmText = event ? 'Confirm' : 'Cancel';
      const confirmMessage = event ? 'Are you sure you want to Confirm?' : 'Are you sure you want to Cancel?';
  
      Swal.fire({
        icon: 'warning',
        title: confirmMessage,
        showCancelButton: true,
        confirmButtonText: confirmText,
        cancelButtonText: 'No',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await confirmAction();
        } else {
          Swal.fire({
            icon: 'info',
            title: 'Booking action canceled',
          });
        }
      });
    }
  };
  
  // const handleConfirmChange = async (event, rowData) => {
  //   Swal.fire({
  //     icon: 'warning',
  //     title: 'Are you sure you want to Confirm?',
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes',
  //     cancelButtonText: 'No',
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       await dispatch(confirm_booking(rowData)); 
        
  //       Swal.fire({
  //         icon: 'success', 
  //         title: 'Booking is confirmed',
  //       });
  //     }
  //     if(result.isConfirmed){
  //       Swal.fire({
  //         icon: 'Cancel', 
  //         title: 'Booking is Cancel',
  //       });
  //     }
  //   });
  // };
  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'fullname', headerName: 'Full Name', width: 150 },
    { field: 'total_passengers', headerName: 'Total passenger', width: 150 },
    { field: 'pickup_address', headerName: 'Pickup Address', width: 200 },
    { field: 'dropoff_address', headerName: 'Dropoff Address', width: 150 },
    { field: 'phone_no', headerName: 'Phone Number', width: 150 },
    { field: 'is_confirm', headerName: 'Confirm', width: 100 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'vehical_type', headerName: 'Vehical_type', width: 150 },
    {
      field: 'actions',
      headerName: 'Update booking',
      width: 200,
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEdit(params.row)}
          >
            Edit
          </Button>
        </div>
      ),
    },
    {
      field: 'action',
      headerName: 'Confirm booking',
      width: 200,
      renderCell: (params) => (
        <div>
          <Select
            value={params.row.is_confirm}
            onChange={(event) => handleConfirmChange(event.target.value, params.row)}
            style={{ marginLeft: '10px' }}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
        </div>
      ),
    },
  ];

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const filteredBookings = Bookings?.allBookings?.filter((booking) =>
    booking.fullname.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <React.Fragment>
      <Box sx={{ height: 400, width: '100%',marginTop:"70px" }} className="data-grid-container">
        <h2 className="heading">Booking</h2>
        <div className="searchField">
          <input
            type="text"
            value={searchText}
            onChange={handleSearchChange}
            placeholder="Search "
            className="searchInput"
          />
        </div>
        {!isEditing && (
          <>

            <DataGrid
              rows={filteredBookings ? filteredBookings : []}
              columns={columns}
              pageSize={5}
              pageSizeOptions={[5]}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </>
        )}
        {isEditing && <EditBooking selectedBooking={selectedBooking} />}
      </Box>
    </React.Fragment>
  );
};

export default Booking;
