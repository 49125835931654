import { createSlice } from '@reduxjs/toolkit';
const initialState = {
	bookings: [],
	IsEditing: false,
};

const bookingSlice = createSlice({
	name: 'bookings',
	initialState: initialState,
	reducers: {
        allBookings(state, action) {
		state.bookings = action.payload.data
	  },
	},
  });
  
  export const bookingAction = bookingSlice.actions;
  
  export default bookingSlice;
