import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, NavLink } from "react-router-dom";
import "./AdminSignUp.css";
import { user_signup } from "../../../../context/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../../assets/icons/gadiRent-Logo-New.png"

const validationSchema = Yup.object({
  password: Yup.string()
    // .matches(
    //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    // )
    .required("Password is required")
    ,
  cpassword: Yup.string()
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both passwords need to be the same"
      ),
    })
    .required("Confirm password is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string()
    .required("Phone is required")
    .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits without special characters"),
});

const handleKeyDown = (evt) => {
  if (evt.key === 'Backspace') {
    return;
  }
  if (!/^\d$/.test(evt.key)) {
    evt.preventDefault(); 
  }
};

function SignUP() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const isLoading = useSelector((state)=>state.alertReducer.isLoading)
  console.log("isLoading",isLoading)
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      cpassword: "",
      phone: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
        console.log("Form values:", values);
        dispatch(user_signup(1, values, navigate));
    },
  });

  return (
    <div className="auth-wrapper">
      <div className="mt-5">
        <img src={logo} alt="logo"/>
      </div>
      <div className="auth-inner">
        <h3>Sign Up</h3>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            <label className="form-label">Email address</label>
            <input
              type="email"
              className="form-control"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="mb-3">
            <label className="form-label">Mobile number</label>
            <input
              type="tel"
              className="form-control"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onKeyDown={handleKeyDown}
              onBlur={formik.handleBlur}
            />
            {formik.touched.phone && formik.errors.phone ? (
              <div className="error">{formik.errors.phone}</div>
            ) : null}
          </div>
          <div className="mb-3">
            <label className="form-label">Password</label>
            <input
              type="password"
              className="form-control"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="error">{formik.errors.password}</div>
            ) : null}
          </div>
          <div className="mb-3">
            <label className="form-label">Confirm Password</label>
            <input
              type="password"
              className="form-control"
              name="cpassword"
              value={formik.values.cpassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.cpassword && formik.errors.cpassword ? (
              <div className="error">{formik.errors.cpassword}</div>
            ) : null}
          </div>
          <div className="d-grid">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          <p className="forgot-password text-right">
            Already signed up? Please{" "}
            <NavLink to={"/"}>login</NavLink>
          </p>
        </form>
      </div>
    </div>
  );
}

export default SignUP;
