import AdminLayout from "../components/layouts/AdminLayout";
import EditProfile from "../modules/admin/profile/EditProfile";
import AddNewVehical from "../modules/admin/vehical/AddNewVehical";
import EditVehical from "../modules/admin/vehical/EditVehical"
import VehicalList from "../modules/admin/vehical/VehicalList";
import Register from "../modules/authentication/admin/adminSignUp/AdminRegister";
import VehicalDetail from "../modules/authentication/admin/adminSignUp/AdminVehicalDetails";
import Dashboard from "../components/Dashboard/DashBoard";
import Booking from "../modules/admin/vehical/Booking/Booking";
import EditBooking from "../modules/admin/vehical/Booking/EditBooking"
import Customers from "../modules/admin/customers/Customers";


const AdminRoutes = [
  {
    path: "/admin/dashboard",
    exact: true,
    layout: AdminLayout,
    element: <AdminLayout> <Dashboard /> </AdminLayout>,
    children: [
      {
        path: "booking",
        exact: true,
        element: <Booking />
      },
      {
        path: "edit-booking",
        exact: true,
        element: <EditBooking />
      },
      {
        path: "vehical",
        exact: true,
        element: <VehicalList />
      },
      {
        path: "add-new",
        exact: true,
        element: <AddNewVehical />
      },
      {
        path: "products",
        exact: true,
        element: <h1>Hey there from products in admin</h1>,
      },
      {
        path: "customers",
        exact: true,
        element: <Customers/>,
      },
      {
        path: "edit-profile",
        exact: true,
        element: <EditProfile />,
      },
      {
        path: "edit-vehical",
        exact: true,
        element: <EditVehical />,
      }
    ],
  },
  {
    path: "/admin/register",
    exact: true,
    layout: AdminLayout,
    element: <AdminLayout><Register /></AdminLayout>
  },
  {
    path: "/admin/vehical-detail",
    exact: true,
    layout: AdminLayout,
    element: <AdminLayout><VehicalDetail /></AdminLayout>
  },
  
];

export default AdminRoutes;
