const toast_config = {
	position: "top-right",
	autoClose: 3000,
	hideProgressBar: true,
	newestOnTop: false,
	rtl: false,
	theme: "colored",
	limit: 1
};

export {
	toast_config
};