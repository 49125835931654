import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./AdminSignIn.css";
import { useDispatch } from "react-redux";
import { user_signin } from "../../../../context/actions/userAction";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import logo from "../../../../assets/icons/gadiRent-Logo-New.png"


const loginInitialValues = {
  email: "",
  password: "",
  showPassword: false, // Added showPassword field to toggle password visibility
};

const loginValidationSchema = Yup.object({
  email: Yup.string().email().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const redirectToAdminPanel = (data, navigate) => {
  if (data.active_step === 3) navigate("/admin/dashboard/vehical");
  else if (data.active_step === 2) navigate("/admin/vehical-detail");
  else if (data.active_step === 1) navigate("/admin/register");
};

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const loginFormik = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      console.log("Form values:", values);
      dispatch(user_signin(1, values, navigate));
    },
  });

  return (
    <div className="auth-wrapper">
      <div className="auth-img mt-5 mb-5">
        <img src={logo} alt="logo"/>
      </div>
      <div className="auth-inner">
        <h3>Sign In</h3>
        <form onSubmit={loginFormik.handleSubmit}>
          <div className="mb-3">
            <label className="form-label">Email address</label>
            <input
              type="email"
              className="form-control"
              name="email"
              value={loginFormik.values.email}
              onChange={loginFormik.handleChange}
              onBlur={loginFormik.handleBlur}
            />
            {loginFormik.touched.email && loginFormik.errors.email && (
              <div className="error">{loginFormik.errors.email}</div>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Password</label>
            <div className="input-group">
              <input
                type={loginFormik.values.showPassword ? "text" : "password"}
                className="form-control"
                name="password"
                value={loginFormik.values.password}
                onChange={loginFormik.handleChange}
                onBlur={loginFormik.handleBlur}
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() =>
                  loginFormik.setFieldValue(
                    "showPassword",
                    !loginFormik.values.showPassword
                  )
                }
              >
                {loginFormik.values.showPassword ? (
                  <AiFillEyeInvisible />
                ) : (
                  <AiFillEye />
                )}
              </button>
            </div>
            {loginFormik.touched.password && loginFormik.errors.password && (
              <div className="error">{loginFormik.errors.password}</div>
            )}
          </div>
          <div className="mb-3">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="customCheck1"
              />
              <label className="form-check-label" htmlFor="customCheck1">
                Remember me
              </label>
            </div>
          </div>
          <div className="d-grid">
            <button type="submit" className="btn btn-primary">
             Submit
            </button>
          </div>
          <p className="forgot-password text-right">
            Not yet signed up? Please click{" "}
            <NavLink to="/admin/signup">here</NavLink>
          </p>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
