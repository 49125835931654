import { toast } from "react-toastify";
import ApiClient from "../../api-client";
import { handleResponse } from "../../api-client/handleResponse";
import { setRequestConfigs } from "../../api-client/setRequestConfigs";
import { API_URL } from "../../environment";
import { alertActions } from "../reducers/alertReducer";
import { userActions } from "../reducers/userReducer";
import { toast_config } from "../../utils/constants";
import { redirectToAdminPanel } from "../../modules/authentication/admin/adminSignIn/AdminSignIn";

const user_signin = (role, payload, navigate) => {
  return async(dispatch) => {
      dispatch(alertActions.IsLoading({ data: true }));
      const serverRequest = setRequestConfigs();
      let response;
      try {
        response = await serverRequest.post("/user/login", {
          email: payload.email,
          password: payload.password,
        });
        if (response.data.code === 200) {
          dispatch(alertActions.IsLoading({ data: false }));
          toast.success(response.data.message,toast_config)
          dispatch(userActions.loginSuccess({ data: response.data.data }));
          localStorage.setItem("token", response.data.data?.access_token);
          localStorage.setItem("user", JSON.stringify(response.data.data?.user));
          redirectToAdminPanel(response.data?.data?.user, navigate)
        } else {
          dispatch(alertActions.IsLoading({ data: false }));
          toast.error(response.data.message,toast_config)
        }
      } catch (error) {
        console.log("error===>", error);
        toast.error(error.response?.data?.message,toast_config)
        dispatch(alertActions.IsLoading({ data: false }));
      }
  };
};

const user_signout = () => {
  return (dispatch, getState) => {
    const {
      user: { token },
    } = getState();
    ApiClient.post(`${API_URL}/user/signout`, {}, `${token}`, null).then(
      () => {}
    );
  };
};

const user_signup = (role, payload, navigate) => {
  return async (dispatch) => {
      dispatch(alertActions.IsLoading({ data: true }));
      const serverRequest = setRequestConfigs();
      let response;
      try {
        if (role === 1) {
          response = await serverRequest.post("/user/register-vendor", payload);
        } else {
          response = await serverRequest.post("/admin/signup-admin", payload);
        }
        if (response.data.code === 200) {
          dispatch(userActions.loginSuccess({ data: response.data.data }));
          dispatch(alertActions.IsLoading({ data: false }));
          toast.success("user registered successfully",toast_config)
          navigate("/admin/register")
          localStorage.setItem("token", response.data.data.access_token);
          localStorage.setItem("user", JSON.stringify(response.data.data?.user));
        } else {
          const err = handleResponse(response?.data);
          dispatch(alertActions.IsLoading({ data: false }));
          toast.error(response.data.message,toast_config)
        }
      } catch (error) {
        handleResponse(error.response?.data);
        toast.warning("something went wrong",toast_config)
      }
  };
};

const register_company = (token, payload, navigate) => {
  return async(dispatch) => {
      dispatch(alertActions.IsLoading({ data: true }));
      const serverRequest = setRequestConfigs(token);
      let response;
      try {
        response = await serverRequest.post("/user/register-compnay", payload);
        if (response.data.code === 200) {
          dispatch(alertActions.IsLoading({ data: false }));
          toast.success("Company registered successfully",toast_config)
          navigate("/admin/vehical-detail")
        } else {
          const err = handleResponse(response?.data);
          dispatch(alertActions.IsLoading({ data: false }));
          toast.error("Error in registering company",toast_config)
        }
      } catch (error) {
        console.log("error===>", error);
        handleResponse(error.response?.data);
        toast.warning("something went wrong",toast_config)
        dispatch(alertActions.IsLoading({ data: false }));
      }
  };
};

const update_company_details = (token, payload, navigate) => {
  return async(dispatch) => {
      dispatch(alertActions.IsLoading({ data: true }));
      const serverRequest = setRequestConfigs(token);
      let response;
      try {
        response = await serverRequest.put("/company/update-companyInfo", payload);
        if (response.data.code === 200) {
          dispatch(alertActions.IsLoading({ data: false }));
          toast.success("Company details updated successfully",toast_config)
          navigate("/admin/dashboard/vehical")
        } else {
          const err = handleResponse(response?.data);
          dispatch(alertActions.IsLoading({ data: false }));
          toast.error("Error in updating company",toast_config)
        }
      } catch (error) {
        console.log("error===>", error);
        handleResponse(error.response?.data);
        toast.warning("something went wrong",toast_config)
        dispatch(alertActions.IsLoading({ data: false }));
      }
  };
};



const forgot_password = (role, payload) => {
  return async (dispatch) => {
    dispatch(alertActions.IsLoading({ data: true }));
    const serverRequest = setRequestConfigs();
    let response;
    try {
      response = await serverRequest.post("/auth/forget-password", {
        email: payload.email,
        role: role,
      });
      if (response.data.code === 200) {
        dispatch(alertActions.IsLoading({ data: false }));
        dispatch(alertActions.successMsg({ data: response.data.message }));
      } else {
        const err = handleResponse(response?.data);
        dispatch(alertActions.IsLoading({ data: false }));
        dispatch(alertActions.errorMsg({ data: err.message }));
      }
    } catch (error) {
      console.log("error===>", error);
    }
  };
};

const reset_password = (queryParam, payload) => {
  return async (dispatch) => {
    dispatch(alertActions.IsLoading({ data: true }));
    const serverRequest = setRequestConfigs();
    let response;
    try {
      response = await serverRequest.post(
        `/auth/reset-password/${queryParam}`,
        { password: payload.password, confirm_password: payload.cpassword }
      );
      if (response.data.code === 200) {
        dispatch(alertActions.IsLoading({ data: false }));
        dispatch(alertActions.successMsg({ data: response.data.message }));
      } else {
        const err = handleResponse(response?.data);
        dispatch(alertActions.IsLoading({ data: false }));
        dispatch(alertActions.errorMsg({ data: err.message }));
      }
    } catch (error) {
      console.log("error===>", error);
    }
  };
};


export {
  user_signin,
  user_signup,
  user_signout,
  forgot_password,
  reset_password,
  register_company,
  update_company_details
};
