/* eslint-disable no-undef */

//For local setup 
const local = {
	API_URL: "http://localhost:4000" 
};

//For staging server
const staging = {
	API_URL: "http://localhost:4000"
};

//For live server
const live = {
	API_URL: "http://localhost:4000"
};
console.log(process.env.REACT_APP_ENV)
if (process.env.REACT_APP_ENV === "local") module.exports = local;
else if (process.env.REACT_APP_ENV === "staging") module.exports = staging;
else if (process.env.REACT_APP_ENV === "live") module.exports = live;
else module.exports = staging;
