import React, { useEffect, useReducer, useCallback, useState } from "react";
import { Formik, useFormik,setFieldValue } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { useLocation, useNavigate } from "react-router-dom";
import { delete_vehical_image, edit_vehical } from "../../../context/actions/vehicalAction";

const validationSchemaCar = Yup.object({
  car_seating_capacity: Yup.string().required(),
  car_rate_per_km: Yup.string().required(),
  car_ac_or_nonAc: Yup.string().required(),
});
const validationSchemaBus = Yup.object({
  bus_seating_capacity: Yup.string().required(),
  bus_ac_or_nonAc: Yup.string().required(),
  bus_rate_per_km: Yup.string().required(),
});
const validationSchemaOther = Yup.object({
  other_seating_capacity: Yup.string().required(),
  other_ac_or_nonAc: Yup.string().required(),
  other_rate_per_km: Yup.string().required(),
});

function EditVehical() {
  const [CarBlock, setCarBlock] = useState(true);
  const [BusBlock, setBusBlock] = useState(false);
  const [OtherBlock, setOtherBlock] = useState(false);
  const [Images, setImages] = useState([]);
  const [editImages, setEditImages] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const token = useSelector(state=> state.userReducer.token)
  const location = useLocation();
  const EditVehical = location.state;
  const formik_car = useFormik({
    initialValues: {
      car_seating_capacity: "",
      car_ac_or_nonAc: "",
      car_rate_per_km: "",
      type: "car",
    },
    validationSchema: validationSchemaCar,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("seating_capacity", values.car_seating_capacity);
      formData.append("ac_or_nonAc", values.car_ac_or_nonAc);
      formData.append("rate_per_km", values.car_rate_per_km);
      formData.append("type", "car");
      if(Images.length > 0){
        for (let index = 0; index < Images.length; index++) {
          formData.append("image", Images[index]);
        }
      }
      dispatch(edit_vehical(token, formData,EditVehical?.id, navigate));
    },
  });
  const formik_bus = useFormik({
    initialValues: {
      bus_seating_capacity: "",
      bus_ac_or_nonAc: "",
      bus_rate_per_km: "",
      type: "bus",
    },
    validationSchema: validationSchemaBus,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("seating_capacity", values.bus_seating_capacity);
      formData.append("ac_or_nonAc", values.bus_ac_or_nonAc);
      formData.append("rate_per_km", values.bus_rate_per_km);
      formData.append("type", "bus");
      if(Images.length > 0){
        for (let index = 0; index < Images.length; index++) {
          formData.append("image", Images[index]);
        }
      }
      dispatch(edit_vehical(token, formData,EditVehical?.id,navigate));
    },
  });

  const formik_other = useFormik({
    initialValues: {
      other_seating_capacity: "",
      other_ac_or_nonAc: "",
      other_rate_per_km: "",
      type: "other",
    },
    validationSchema: validationSchemaOther,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("seating_capacity", values.other_seating_capacity);
      formData.append("ac_or_nonAc", values.other_ac_or_nonAc);
      formData.append("rate_per_km", values.other_rate_per_km);
      formData.append("type", "other");
      if(Images.length > 0){
        for (let index = 0; index < Images.length; index++) {
          formData.append("image", Images[index]);
        }
      }
      dispatch(edit_vehical(token, formData,EditVehical?.id, navigate));
    },  
  });

  const onDrop = useCallback((acceptedFiles) => {
    setImages((prevImages) => [...prevImages, ...acceptedFiles]);
  }, []);


  const removeImage = (index) => {
      const newImages = [...Images];
      newImages.splice(index, 1);
      setImages(newImages);
  };


  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*", // Allow only image files
  });

  useEffect(() => {
    if (localStorage.getItem("car") === 1) {
      setCarBlock(true);
    }
    if (localStorage.getItem("bus") === 1) {
      setBusBlock(true);
    }
    if (localStorage.getItem("other") === 1) {
      setOtherBlock(true);
    }
  }, []);

  useEffect(()=>{
    if(EditVehical?.type == "car"){
      formik_car.setFieldValue("car_seating_capacity",EditVehical.seating_capacity)
      formik_car.setFieldValue("car_ac_or_nonAc",EditVehical.ac_or_nonAc)
      formik_car.setFieldValue("car_rate_per_km",EditVehical.ratePer_km)
      formik_car.setFieldValue("car_rate_per_km",EditVehical.ratePer_km)
    }else if(EditVehical?.type == "bus"){
      formik_bus.setFieldValue("bus_seating_capacity",EditVehical.seating_capacity)
      formik_bus.setFieldValue("bus_ac_or_nonAc",EditVehical.ac_or_nonAc)
      formik_bus.setFieldValue("bus_rate_per_km",EditVehical.ratePer_km)
    }else{
      formik_other.setFieldValue("other_seating_capacity",EditVehical.seating_capacity)
      formik_other.setFieldValue("other_ac_or_nonAc",EditVehical.ac_or_nonAc)
      formik_other.setFieldValue("other_rate_per_km",EditVehical.ratePer_km)
    }
    setEditImages(EditVehical?.image)
  },[EditVehical])

  return (
    <div class="vehical-auth-wrapper">
      <div class="vehical-auth-inner">
        <h3 style={{ textAlign: "center" }}>Edit Details</h3>
        <>
          {EditVehical?.type == "car" && (
            <>
              <form onSubmit={formik_car.handleSubmit}>
                <label class="form-label">Car</label>
                <div className="vehical_wrapper">
                  <div class="vehical_row">
                    <div class="vehical_column">
                      <label>Seating Capacity:</label>
                      <input
                        type="number"
                        name="car_seating_capacity"
                        id="car_seating_capacity"
                        value={formik_car.values?.car_seating_capacity}
                        onChange={formik_car.handleChange}
                        onBlur={formik_car.handleBlur}
                      />
                      {formik_car.touched.car_seating_capacity &&
                      formik_car.errors.car_seating_capacity ? (
                        <div className="error">
                          {formik_car.errors.car_seating_capacity}
                        </div>
                      ) : null}
                    </div>
                    <div class="vehical_column">
                      <label class="form-label">AC Non-AC:</label>
                      <select
                        onChange={formik_car.handleChange}
                        onBlur={formik_car.handleBlur}
                        name="car_ac_or_nonAc"
                        value={formik_car.values?.car_ac_or_nonAc}
                        id="car_ac_or_nonAc"
                      >
                        <option value="">---Please Select---</option>
                        <option value="AC">AC</option>
                        <option value="NON-AC">Non-AC</option>
                      </select>
                      {formik_car.touched.car_ac_or_nonAc &&
                      formik_car.errors.car_ac_or_nonAc ? (
                        <div className="error">
                          {formik_car.errors.car_ac_or_nonAc}
                        </div>
                      ) : null}
                    </div>
                    <div class="vehical_column">
                      <label class="form-label">Rate Per KM:</label>
                      <input
                        type="number"
                        name="car_rate_per_km"
                        value={formik_car.values?.car_rate_per_km}
                        id="car_rate_per_km"
                        onChange={formik_car.handleChange}
                        onBlur={formik_car.handleBlur}
                      />
                      {formik_car.touched.car_rate_per_km &&
                      formik_car.errors.car_rate_per_km ? (
                        <div className="error">
                          {formik_car.errors.car_rate_per_km}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <label for="car_images">Car Images:</label>
                  <div>
                    <div {...getRootProps()} className="dropzone">
                      <input {...getInputProps()} />
                      <p>click to select images</p>
                    </div>
                  </div>
                  <div className="image-preview">
                    {Images.map((file, index) => (
                      <div key={index}>
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`Image ${index}`}
                          className="preview-image"
                        />
                        <span
                          className="remove-image"
                          onClick={() => removeImage(index,file)}
                        >
                          &#10060; {/* Red cross (X) symbol */}
                        </span>
                      </div>
                    ))}
                    {editImages.length > 0  && editImages.map((file,index)=>(
                      <div key={index}>
                      <img
                        src={`http://localhost:5000/uploads/${file?.filename}`}
                        alt={`Image ${index}`}
                        className="preview-image"
                      />
                      <span
                        className="remove-image"
                        onClick={() =>{
                          const newImages = [...editImages];
                          newImages.splice(index, 1);
                          setEditImages(newImages);
                          dispatch(delete_vehical_image(token,EditVehical?.type,EditVehical?.id,file))
                        }}
                      >
                        &#10060; {/* Red cross (X) symbol */}
                      </span>
                    </div>
                    ))}
                  </div>
                  <div class="button_container">
                    <label>&nbsp;</label>
                    <label class="form-label">&nbsp;</label>
                    <button type="submit" class="btn btn-primary">
                      Edit
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
          {EditVehical?.type == "bus" && (
            <>
              <form onSubmit={formik_bus.handleSubmit}>
                <label class="form-label">Bus</label>
                <div className="vehical_wrapper">
                  <div class="vehical_row">
                    <div class="vehical_column">
                      <label>Seating Capacity:</label>
                      <input
                        type="number"
                        name="bus_seating_capacity"
                        value={formik_bus.values?.bus_seating_capacity}
                        id="bus_seating_capacity"
                        onChange={formik_bus.handleChange}
                        onBlur={formik_bus.handleBlur}
                      />
                      {formik_bus.touched.bus_seating_capacity &&
                      formik_bus.errors.bus_seating_capacity ? (
                        <div className="error">
                          {formik_bus.errors.bus_seating_capacity}
                        </div>
                      ) : null}
                    </div>
                    <div class="vehical_column">
                      <label class="form-label">AC Non-AC:</label>
                      <select
                        onChange={formik_bus.handleChange}
                        onBlur={formik_bus.handleBlur}
                        name="bus_ac_or_nonAc"
                        value={formik_bus.values?.bus_ac_or_nonAc}
                        id="bus_ac_or_nonAc"
                      >
                        <option value="">---Please Select---</option>
                        <option value="AC">AC</option>
                        <option value="NON-AC">Non-AC</option>
                      </select>
                      {formik_bus.touched.bus_ac_or_nonAc &&
                      formik_bus.errors.bus_ac_or_nonAc ? (
                        <div className="error">
                          {formik_bus.errors.bus_ac_or_nonAc}
                        </div>
                      ) : null}
                    </div>
                    <div class="vehical_column">
                      <label class="form-label">Rate Per KM:</label>
                      <input
                        type="number"
                        name="bus_rate_per_km"
                        value={formik_bus.values?.bus_rate_per_km}
                        id="bus_rate_per_km"
                        onChange={formik_bus.handleChange}
                        onBlur={formik_bus.handleBlur}
                      />
                      {formik_bus.touched.bus_rate_per_km &&
                      formik_bus.errors.bus_rate_per_km ? (
                        <div className="error">
                          {formik_bus.errors.bus_rate_per_km}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <label for="car_images">Bus Images:</label>
                  <div >
                    <div {...getRootProps()} className="dropzone">
                      <input {...getInputProps()} />
                      <p>click to select images</p>
                    </div>
                  </div>
                  <div className="image-preview">
                    {Images.map((file, index) => (
                      <div key={index}>
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`Image ${index}`}
                          className="preview-image"
                        />
                        <span
                          className="remove-image"
                          onClick={() => removeImage(index,file)}
                        >
                          &#10060; {/* Red cross (X) symbol */}
                        </span>
                      </div>
                      
                    ))}
                    {editImages.length > 0  && editImages.map((file,index)=>(
                      <div key={index}>
                      <img
                        src={`http://localhost:5000/uploads/${file?.filename}`}
                        alt={`Image ${index}`}
                        className="preview-image"
                      />
                      <span
                        className="remove-image"
                        onClick={() => {
                          const newImages = [...editImages];
                          newImages.splice(index, 1);
                          setEditImages(newImages);
                          dispatch(delete_vehical_image(token,EditVehical?.type,EditVehical?.id,file))
                        }}
                      >
                        &#10060; {/* Red cross (X) symbol */}
                      </span>
                    </div>
                    ))}
                  </div>
                  <div class="button_container">
                    <label>&nbsp;</label>
                    <label class="form-label">&nbsp;</label>
                    <button type="submit" class="btn btn-primary">
                      Edit
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
          {EditVehical?.type == "other" && (
            <>
              <form onSubmit={formik_other.handleSubmit}>
                <label class="form-label">Other Vehicle</label>
                <div className="vehical_wrapper">
                  <div class="vehical_row">
                    <div class="vehical_column">
                      <label>Seating Capacity:</label>
                      <input
                        type="number"
                        name="other_seating_capacity"
                        value={formik_other.values?.other_seating_capacity}
                        id="other_seating_capacity"
                        onChange={formik_other.handleChange}
                        onBlur={formik_other.handleBlur}
                      />
                      {formik_other.touched.other_seating_capacity &&
                      formik_other.errors.other_seating_capacity ? (
                        <div className="error">
                          {formik_other.errors.other_seating_capacity}
                        </div>
                      ) : null}
                    </div>
                    <div class="vehical_column">
                      <label class="form-label">AC Non-AC:</label>
                      <select
                        onChange={formik_other.handleChange}
                        onBlur={formik_other.handleBlur}
                        name="other_ac_or_nonAc"
                        value={formik_other.values?.bus_ac_or_nonAc}
                        id="other_ac_or_nonAc"
                      >
                        <option value="">---Please Select---</option>
                        <option value="AC">AC</option>
                        <option value="NON-AC">Non-AC</option>
                      </select>
                      {formik_other.touched.other_ac_or_nonAc &&
                      formik_other.errors.other_ac_or_nonAc ? (
                        <div className="error">
                          {formik_other.errors.other_ac_or_nonAc}
                        </div>
                      ) : null}
                    </div>
                    <div class="vehical_column">
                      <label class="form-label">Rate Per KM:</label>
                      <input
                        type="number"
                        name="other_rate_per_km"
                        value={formik_other.values?.other_rate_per_km}
                        id="other_rate_per_km"
                        onChange={formik_other.handleChange}
                        onBlur={formik_other.handleBlur}
                      />
                      {formik_other.touched.other_rate_per_km &&
                      formik_other.errors.other_rate_per_km ? (
                        <div className="error">
                          {formik_other.errors.other_rate_per_km}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <label for="car_images">Other Vehical Images:</label>
                  <div>
                    <div {...getRootProps()} className="dropzone">
                      <input {...getInputProps()} />
                      <p>click to select images</p>
                    </div>
                  </div>
                  <div className="image-preview">
                    {Images.map((file, index) => (
                      <div key={index}>
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`Image ${index}`}
                          className="preview-image"
                        />
                        <span
                          className="remove-image"
                          onClick={() => removeImage(index,file)}
                        >
                          &#10060; {/* Red cross (X) symbol */}
                        </span>
                      </div>
                    ))}
                    {editImages.length > 0  && editImages.map((file,index)=>(
                      <div key={index}>
                      <img
                        src={`http://localhost:5000/uploads/${file?.filename}`}
                        alt={`Image ${index}`}
                        className="preview-image"
                      />
                      <span
                        className="remove-image"
                        onClick={() => {
                          const newImages = [...editImages];
                          newImages.splice(index, 1);
                          setEditImages(newImages);
                          dispatch(delete_vehical_image(token,EditVehical?.type,EditVehical?.id,file))
                        }}
                      >
                        &#10060; {/* Red cross (X) symbol */}
                      </span>
                    </div>
                    ))}
                  </div>
                  <div class="button_container">
                    <label>&nbsp;</label>
                    <label class="form-label">&nbsp;</label>
                    <button type="submit" class="btn btn-primary">
                      Edit
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
        </>
      </div>
    </div>
  );
}

export default EditVehical;