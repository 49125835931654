import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../../../api-client/setRequestConfigs";
import { toast } from "react-toastify";
import { toast_config } from "../../../utils/constants";
import { update_company_details } from "../../../context/actions/userAction";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object({
  firstname: Yup.string().required("Firstname is required").max(50),
  lastname: Yup.string().required("Lastname is required").max(50),
  dob: Yup.string().required("DOB is required"),
  companyName: Yup.string().required("companyName is required"),
  companyDesc: Yup.string().required("companyDesc is required"),
  foundationYear: Yup.string().required("foundationYear is required"),
  city: Yup.string().required("city is required"),
  state: Yup.string().required("state is required"),
  country: Yup.string().required("country is required"),
  gstNumber: Yup.string().required("gstNumber is required"),
  companyAddress: Yup.string(),
});

function EditProfile() {
  const token = useSelector(state=> state.userReducer.token)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      dob: "",
      companyName: "",
      companyDesc: "",
      foundationYear: "",
      city: "",
      state: "",
      country: "",
      gstNumber: "",
      companyAddress: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values,id) => {
      try {
        dispatch(update_company_details( token, values, navigate ))
      } catch (error) {
        console.error("Error updating profile:", error);
      }
    },
  });

  const getUserProfile = async()=>{
    let response
    try {
      response = await axios.get(BASE_URL + `/company/show-companyInfo`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if(response.data.code === 200){
        const {firstname, lastname, dob, company_name, company_desc, foundation_year, city, state, country, gst_number, company_address  } = response?.data?.data.CompanyData
        formik.setFieldValue('firstname', firstname)
        formik.setFieldValue('lastname', lastname)
        formik.setFieldValue('dob', dob)
        formik.setFieldValue('companyName', company_name)
        formik.setFieldValue('companyDesc', company_desc)
        formik.setFieldValue('foundationYear', foundation_year)
        formik.setFieldValue('city', city)
        formik.setFieldValue('state', state)
        formik.setFieldValue('country', country)
        formik.setFieldValue('gstNumber',gst_number)
        formik.setFieldValue('companyAddress', company_address)
      }
      
    } catch (error) {
      toast.error(error.response?.data?.message,toast_config)
    }
  }

  useEffect(() => {
      getUserProfile()
  }, [])
  
  

  return (
    <div className="register-auth-wrapper">
      <div className="register-auth-inner">
        <h3>Edit Company Details</h3>
        <form onSubmit={formik.handleSubmit}>
          <div className="register_row">
            <div className="register_column">
              <label className="form-label">Firstname</label>
              <input
                type="text"
                className="form-control"
                name="firstname"
                value={formik.values.firstname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.firstname && formik.errors.firstname ? (
                <div className="error">{formik.errors.firstname}</div>
              ) : null}
            </div>
            <div className="register_column">
              <label className="form-label">Lastname</label>
              <input
                type="text"
                className="form-control"
                name="lastname"
                value={formik.values.lastname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.lastname && formik.errors.lastname ? (
                <div className="error">{formik.errors.lastname}</div>
              ) : null}
            </div>
          </div>
          <div className="register_row">
            <div className="register_column">
              <label className="form-label">Date of birth</label>
              <input
                type="date"
                className="form-control"
                name="dob"
                value={formik.values.dob}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.dob && formik.errors.dob ? (
                <div className="error">{formik.errors.dob}</div>
              ) : null}
            </div>
            <div className="register_column">
              <label className="form-label">Company Name</label>
              <input
                type="text"
                className="form-control"
                name="companyName"
                value={formik.values.companyName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.companyName && formik.errors.companyName ? (
                <div className="error">{formik.errors.companyName}</div>
              ) : null}
            </div>
          </div>
          <div className="register_row">
            <div className="register_column">
              <label className="form-label">Company Description</label>
              <input
                type="text"
                className="form-control"
                name="companyDesc"
                value={formik.values.companyDesc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.companyDesc && formik.errors.companyDesc ? (
                <div className="error">{formik.errors.companyDesc}</div>
              ) : null}
            </div>
            <div className="register_column">
              <label className="form-label">Foundation Year</label>
              <input
                type="text"
                className="form-control"
                name="foundationYear"
                value={formik.values.foundationYear}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.foundationYear && formik.errors.foundationYear ? (
                <div className="error">{formik.errors.foundationYear}</div>
              ) : null}
            </div>
          </div>
          <div className="register_row">
            <div className="register_column">
              <label className="form-label">City</label>
              <input
                type="text"
                className="form-control"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.city && formik.errors.city ? (
                <div className="error">{formik.errors.city}</div>
              ) : null}
            </div>
            <div className="register_column">
              <label className="form-label">State</label>
              <input
                type="text"
                className="form-control"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.state && formik.errors.state ? (
                <div className="error">{formik.errors.state}</div>
              ) : null}
            </div>
          </div>
          <div className="register_row">
            <div className="register_column">
              <label className="form-label">Country</label>
              <input
                type="text"
                className="form-control"
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.country && formik.errors.country ? (
                <div className="error">{formik.errors.country}</div>
              ) : null}
            </div>
            <div className="register_column">
              <label className="form-label">GST Number</label>
              <input
                type="text"
                className="form-control"
                name="gstNumber"
                value={formik.values.gstNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.gstNumber && formik.errors.gstNumber ? (
                <div className="error">{formik.errors.gstNumber}</div>
              ) : null}
            </div>
          </div>
          <div className="register_row">
            <div className="register_column">
              <label className="form-label">Company address</label>
              <input
                type="text"
                className="form-control"
                name="companyAddress"
                value={formik.values.companyAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.companyAddress && formik.errors.companyAddress ? (
                <div className="error">{formik.errors.companyAddress}</div>
              ) : null}
            </div>
            <div className="register_column">
            </div>
          </div>
          <div className="button_container">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditProfile;
