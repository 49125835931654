import { createSlice } from '@reduxjs/toolkit';
const initialState = {
	orders: [],
};

const orderSlice = createSlice({
	name: 'order',
	initialState: initialState,
	reducers: {
	  productList(state, action) {
		state.products = action.payload.data
	  },
	},
  });
  
  export const orderActions = orderSlice.actions;
  
  export default orderSlice;
