import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
// const TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzMGEzOGUwYjczZTg5NTczZDAxNmZmNSIsImlzQWRtaW4iOnRydWUsImlhdCI6MTY2MzA3NTI3OSwiZXhwIjoxNjYzMTYxNjc5fQ.sLeBTfgAymobkEF1rcOzexB8-ixEXKWat-7lRFfaKVQ';
console.log("BASE_URL",BASE_URL)
const publicRequest = axios.create(
  {
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "application/json" 
      }
  }
);

function setToken(token){
  return axios.create({
    baseURL: BASE_URL,
    headers: {
      'Authorization': `Bearer ${token}`,
      "Content-Type": "application/json"
    }
  });
}


export const setRequestConfigs = (token) =>{
   if(token) return setToken(token)
   else return publicRequest
}