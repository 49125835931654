import React, { useEffect, useCallback, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./AddNewVehical.css";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import Select from 'react-select';
import { add_new_vehical} from "../../../context/actions/vehicalAction";
import Loader from "../../../components/spinner/Loader";
import { useNavigate } from "react-router-dom";

const validationSchemaCar = Yup.object({
  car_seating_capacity: Yup.string().required(),
  car_rate_per_km: Yup.string().required(),
  car_ac_or_nonAc: Yup.string().required(),
});
const validationSchemaBus = Yup.object({
  bus_seating_capacity: Yup.string().required(),
  bus_ac_or_nonAc: Yup.string().required(),
  bus_rate_per_km: Yup.string().required(),
});
const validationSchemaOther = Yup.object({
  other_seating_capacity: Yup.string().required(),
  other_ac_or_nonAc: Yup.string().required(),
  other_rate_per_km: Yup.string().required(),
});

function AddNewVehical() {
  const numberOptions = Array.from({ length: 40 }, (_, index) => ({
    value: (index + 1).toString(),
    label: (index + 1).toString(),
  }));
  const [selectedNumber, setSelectedNumber] = React.useState(null);

  const [CarBlock, setCarBlock] = useState(true);
  const [BusBlock, setBusBlock] = useState(false);
  const [OtherBlock, setOtherBlock] = useState(false);
  const [carImages, setCarImages] = useState([]);
  const [busImages, setBusImages] = useState([]);
  const [otherImages, setOtherImages] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const token = useSelector((state) => state.userReducer.token);
  const isLoading = useSelector((state) => state.alertReducer.isLoading)
  
  const formik_car = useFormik({
    initialValues: {
      car_seating_capacity: "",
      car_ac_or_nonAc: "",
      car_rate_per_km: "",
      type: "car",
    },
    validationSchema: validationSchemaCar,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("car_seating_capacity", values.car_seating_capacity);
      formData.append("car_ac_or_nonAc", values.car_ac_or_nonAc);
      formData.append("car_rate_per_km", values.car_rate_per_km);
      formData.append("type", "car");
  
      let imagesArray = carImages; // Use carImages for car type
      for (let index = 0; index < imagesArray.length; index++) {
        formData.append("image", imagesArray[index]);
      }
      dispatch(add_new_vehical(token, formData, navigate));
    },
  });

  const formik_bus = useFormik({
    initialValues: {
      bus_seating_capacity: "",
      bus_ac_or_nonAc: "",
      bus_rate_per_km: "",
      type: "bus",
    },
    validationSchema: validationSchemaBus,
    onSubmit: async (values) => {
      console.log("Form values bus:", values);
      const formData = new FormData();
      formData.append("bus_seating_capacity", values.bus_seating_capacity);
      formData.append("bus_ac_or_nonAc", values.bus_ac_or_nonAc);
      formData.append("bus_rate_per_km", values.bus_rate_per_km);
      formData.append("type", "bus");
      for (let index = 0; index < busImages.length; index++) {
        formData.append("image", busImages[index]);
      }
      dispatch(add_new_vehical(token, formData));
    },
  });

  const formik_other = useFormik({
    initialValues: {
      other_seating_capacity: "",
      other_ac_or_nonAc: "",
      other_rate_per_km: "",
      type: "other",
    },
    validationSchema: validationSchemaOther,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("other_seating_capacity", values.other_seating_capacity);
      formData.append("other_ac_or_nonAc", values.other_ac_or_nonAc);
      formData.append("other_rate_per_km", values.other_rate_per_km);
      formData.append("type", "other");
      for (let index = 0; index < otherImages.length; index++) {
        formData.append("image", otherImages[index]);
      }
      dispatch(add_new_vehical(token, formData));
    },
  });

  const onDrop = useCallback((acceptedFiles) => {
    let imageType = localStorage.getItem("type")
    if (imageType == "car")
      setCarImages((prevImages) => [...prevImages, ...acceptedFiles]);
    else if (imageType == "bus")
      setBusImages((prevImages) => [...prevImages, ...acceptedFiles]);
    else if (imageType == "other") setOtherImages((prevImages) => [...prevImages, ...acceptedFiles]);
  }, []);

  const removeImage = (index) => {
    let imageType = localStorage.getItem("type")
    if (imageType == "car") {
      const newImages = [...carImages];
      newImages.splice(index, 1);
      setCarImages(newImages);
    } else if (imageType == "bus") {
      const newImages = [...busImages];
      newImages.splice(index, 1);
      setBusImages(newImages);
    } else {
      const newImages = [...otherImages];
      newImages.splice(index, 1);
      setOtherImages(newImages);
    }

  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*", // Allow only image files
  });
  const handleImageType = (type) => {
    localStorage.setItem("type", type)
  };

  useEffect(() => {
    if (localStorage.getItem("type") == "car") {
      setCarBlock(true)
      setBusBlock(false)
      setOtherBlock(false)
    }
    if (localStorage.getItem("type") == "bus") {
      setBusBlock(true)
      setCarBlock(false)
      setOtherBlock(false)
    }
    if (localStorage.getItem("type") == "other") {
      setBusBlock(false)
      setCarBlock(false)
      setOtherBlock(true)
    }
  }, [])

  const handleKeyDown = (evt) => {
    if (evt.key === 'Backspace') {
      return;
    }
    if (!/^\d$/.test(evt.key)) {
      evt.preventDefault();
    }
  };

  return (
    <div class="vehical-auth-wrapper">
      <div class="vehical-auth-inner">
        <h3 style={{ textAlign: "center" }}>Vehicle Details</h3>
        <div class="checkbox-row">
          <div class="checkbox-item">
            <input
              onChange={(e) => {
                if (e.target.value == "car") {
                  setOtherBlock(false)
                  setBusBlock(false)
                  setCarBlock(true)
                  localStorage.setItem("type", "car")
                }
              }}
              type="radio"
              id="checkbox1"
              value="car"
              name="type"
              checked={CarBlock}
            />
            <label className="checkbox-label" for="checkbox1">
              Car
            </label>
          </div>
          <div class="checkbox-item">
            <input
              onChange={(e) => {
                if (e.target.value == "bus") {
                  setOtherBlock(false)
                  setBusBlock(true)
                  setCarBlock(false)
                  localStorage.setItem("type", "bus")
                }
              }}
              type="radio"
              id="checkbox2"
              value="bus"
              name="type"
              checked={BusBlock}
            />
            <label className="checkbox-label" for="checkbox2">
              Bus
            </label>
          </div>
          <div class="checkbox-item">
            <input
              onChange={(e) => {
                if (e.target.value == "other") {
                  setOtherBlock(true)
                  setBusBlock(false)
                  setCarBlock(false)
                  localStorage.setItem("type", "other")
                }
              }}
              type="radio"
              id="checkbox3"
              value="other"
              name="type"
              checked={OtherBlock}
            />
            <label className="checkbox-label" for="checkbox3">
              Groom/Bride
            </label>
          </div>
        </div>
        <>
          {CarBlock && (
            <>
              <form onSubmit={formik_car.handleSubmit}>
                <label class="form-label">Car</label>
                <div className="vehical_wrapper">
                  <div class="vehical_row">
                    <div class="vehical_column">
                      <label>Seating Capacity:</label>
                      <Select
                        options={numberOptions}
                        type="number"
                        onKeyDown={handleKeyDown}
                        name="car_seating_capacity"
                        value={numberOptions.find(option => option.value === selectedNumber)}
                        onChange={(selectedOption) => {
                          setSelectedNumber(selectedOption.value);
                          formik_car.setFieldValue('car_seating_capacity', selectedOption.value);
                        }}
                        onBlur={formik_car.handleBlur}
                      />
                      {/* <input
                        type="number"
                        name="car_seating_capacity"
                        id="car_seating_capacity"
                        onChange={formik_car.handleChange}
                        onBlur={formik_car.handleBlur}
                      /> */}
                      {formik_car.touched.car_seating_capacity &&
                        formik_car.errors.car_seating_capacity ? (
                        <div className="error">
                          {formik_car.errors.car_seating_capacity}
                        </div>
                      ) : null}
                    </div>
                    <div class="vehical_column">
                      <label class="form-label">AC Non-AC:</label>
                      <select
                        onChange={formik_car.handleChange}
                        onBlur={formik_car.handleBlur}
                        name="car_ac_or_nonAc"
                        id="car_ac_or_nonAc"
                      >
                        <option value="">---Please Select---</option>
                        <option value="AC">AC</option>
                        <option value="NON-AC">Non-AC</option>
                      </select>
                      {formik_car.touched.car_ac_or_nonAc &&
                        formik_car.errors.car_ac_or_nonAc ? (
                        <div className="error">
                          {formik_car.errors.car_ac_or_nonAc}
                        </div>
                      ) : null}
                    </div>
                    <div class="vehical_column">
                      <label class="form-label">Rate Per KM:</label>
                      <input
                        type="number"
                        name="car_rate_per_km"
                        id="car_rate_per_km"
                        onChange={formik_car.handleChange}
                        onBlur={formik_car.handleBlur}
                      />
                      {formik_car.touched.car_rate_per_km &&
                        formik_car.errors.car_rate_per_km ? (
                        <div className="error">
                          {formik_car.errors.car_rate_per_km}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <label for="car_images">Car Images:</label>
                  <div onClick={() => handleImageType("car")}>
                    <div {...getRootProps()} className="dropzone">
                      <input {...getInputProps()} />
                      <p>click to select images</p>
                    </div>
                  </div>
                  <div className="image-preview">
                    {carImages.map((file, index) => (
                      <div key={index}>
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`Image ${index}`}
                          className="preview-image"
                        />
                        <span
                          className="remove-image"
                          onClick={() => removeImage(index)}
                        >
                          &#10060; {/* Red cross (X) symbol */}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div class="button_container">
                    <label>&nbsp;</label>
                    <label class="form-label">&nbsp;</label>
                    <button type="submit" class="btn btn-primary" >
                      +Add
                    </button> 
  
                  </div>
                </div>
              </form>
            </>
          )}
          {BusBlock && (
            <>
              <form onSubmit={formik_bus.handleSubmit}>
                <label class="form-label">Bus</label>
                <div className="vehical_wrapper">
                  <div class="vehical_row">
                    <div class="vehical_column">
                      <label>Seating Capacity:</label>
                      <Select
                        options={numberOptions}
                        type="number"
                        onKeyDown={handleKeyDown}
                        name="bus_seating_capacity"
                        value={numberOptions.find(option => option.value === selectedNumber)}
                        onChange={(selectedOption) => {
                          setSelectedNumber(selectedOption.value);
                          formik_bus.setFieldValue('bus_seating_capacity', selectedOption.value);
                        }}
                        onBlur={formik_bus.handleBlur}
                      />
                      {formik_bus.touched.bus_seating_capacity &&
                        formik_bus.errors.bus_seating_capacity ? (
                        <div className="error">
                          {formik_bus.errors.bus_seating_capacity}
                        </div>
                      ) : null}
                    </div>
                    <div class="vehical_column">
                      <label class="form-label">AC Non-AC:</label>
                      <select
                        onChange={formik_bus.handleChange}
                        onBlur={formik_bus.handleBlur}
                        name="bus_ac_or_nonAc"
                        id="bus_ac_or_nonAc"
                      >
                        <option value="">---Please Select---</option>
                        <option value="AC">AC</option>
                        <option value="NON-AC">Non-AC</option>
                      </select>
                      {formik_bus.touched.bus_ac_or_nonAc &&
                        formik_bus.errors.bus_ac_or_nonAc ? (
                        <div className="error">
                          {formik_bus.errors.bus_ac_or_nonAc}
                        </div>
                      ) : null}
                    </div>
                    <div class="vehical_column">
                      <label class="form-label">Rate Per KM:</label>
                      <input
                        type="number"
                        name="bus_rate_per_km"
                        id="bus_rate_per_km"
                        onChange={formik_bus.handleChange}
                        onBlur={formik_bus.handleBlur}
                      />
                      {formik_bus.touched.bus_rate_per_km &&
                        formik_bus.errors.bus_rate_per_km ? (
                        <div className="error">
                          {formik_bus.errors.bus_rate_per_km}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <label for="car_images">Bus Images:</label>
                  <div onClick={() => handleImageType("bus")}>
                    <div {...getRootProps()} className="dropzone">
                      <input {...getInputProps()} />
                      <p>click to select images</p>
                    </div>
                  </div>
                  <div className="image-preview">
                    {busImages.map((file, index) => (
                      <div key={index}>
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`Image ${index}`}
                          className="preview-image"
                        />
                        <span
                          className="remove-image"
                          onClick={() => removeImage(index)}
                        >
                          &#10060; {/* Red cross (X) symbol */}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div class="button_container">
                    <label>&nbsp;</label>
                    <label class="form-label">&nbsp;</label>
                    <button type="submit" class="btn btn-primary">
                      +Add
                    </button>
                  
                  </div>
                </div>
              </form>
            </>
          )}
          {OtherBlock && (
            <>
              <form onSubmit={formik_other.handleSubmit}>
                <label class="form-label">Other Vehicle</label>
                <div className="vehical_wrapper">
                  <div class="vehical_row">
                    <div class="vehical_column">
                      <label>Seating Capacity:</label>
                      <Select
                        options={numberOptions}
                        type="number"
                        onKeyDown={handleKeyDown}
                        name="other_seating_capacity"
                        value={numberOptions.find(option => option.value === selectedNumber)}
                        onChange={(selectedOption) => {
                          setSelectedNumber(selectedOption.value);
                          formik_other.setFieldValue('other_seating_capacity', selectedOption.value);
                        }}
                        onBlur={formik_other.handleBlur}
                      />
                      {formik_other.touched.other_seating_capacity &&
                        formik_other.errors.other_seating_capacity ? (
                        <div className="error">
                          {formik_other.errors.other_seating_capacity}
                        </div>
                      ) : null}
                    </div>
                    <div class="vehical_column">
                      <label class="form-label">AC Non-AC:</label>
                      <select
                        onChange={formik_other.handleChange}
                        onBlur={formik_other.handleBlur}
                        name="other_ac_or_nonAc"
                        id="other_ac_or_nonAc"
                      >
                        <option value="">---Please Select---</option>
                        <option value="AC">AC</option>
                        <option value="NON-AC">Non-AC</option>
                      </select>
                      {formik_other.touched.other_ac_or_nonAc &&
                        formik_other.errors.other_ac_or_nonAc ? (
                        <div className="error">
                          {formik_other.errors.other_ac_or_nonAc}
                        </div>
                      ) : null}
                    </div>
                    <div class="vehical_column">
                      <label class="form-label">Rate Per KM:</label>
                      <input
                        type="number"
                        name="other_rate_per_km"
                        id="other_rate_per_km"
                        onChange={formik_other.handleChange}
                        onBlur={formik_other.handleBlur}
                      />
                      {formik_other.touched.other_rate_per_km &&
                        formik_other.errors.other_rate_per_km ? (
                        <div className="error">
                          {formik_other.errors.other_rate_per_km}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <label for="car_images">Other Vehical Images:</label>
                  <div onClick={() => handleImageType("other")}>
                    <div {...getRootProps()} className="dropzone">
                      <input {...getInputProps()} />
                      <p>click to select images</p>
                    </div>
                  </div>
                  <div className="image-preview">
                    {otherImages.map((file, index) => (
                      <div key={index}>
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`Image ${index}`}
                          className="preview-image"
                        />
                        <span
                          className="remove-image"
                          onClick={() => removeImage(index)}
                        >
                          &#10060; {/* Red cross (X) symbol */}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div class="button_container">
                    <label>&nbsp;</label>
                    <label class="form-label">&nbsp;</label>
                    {!isLoading ? (<button type="submit" class="btn btn-primary" >
                      +Add
                    </button>) :
                      (<Loader />)}
                  </div>
                </div>
              </form>
            </>
          )}
        </>
      </div>
    </div>
  );
}

export default AddNewVehical;
