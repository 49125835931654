import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { State, City } from "country-state-city";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useDispatch, useSelector } from "react-redux";
import { register_company } from "../../../../context/actions/userAction";
import { useNavigate } from "react-router-dom";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import "./AdminRegister.css";

const validationSchema = Yup.object({
  firstname: Yup.string().required("Firstname is required").max(50),
  lastname: Yup.string().required("Lastname is required").max(50),
  dob: Yup.string().required("DOB is required"),
  companyName: Yup.string().required("Company Name is required").max(50),
  companyDesc: Yup.string().required("Company Description is required").max(200),
  foundationYear: Yup.number().required("Foundation Year is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
  gstNumber: Yup.number().required("GST Number is required"),
  companyAddress: Yup.string(),
});

const handleKeyDown = (evt) => {
  if (evt.key === "Backspace") return;
  if (!/^\d$/.test(evt.key)) evt.preventDefault();
};

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.userReducer.token);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [searchResult, setSearchResult] = useState("");

  const autocompleteRef = useRef();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries: ["places"],
  });

  useEffect(() => {
    const getStates = async () => {
      if (selectedCountry && selectedCountry.value === "IN") {
        const indiaStates = await State.getStatesOfCountry("IN");
        setStates(indiaStates.map((state) => ({ value: state.isoCode, label: state.name })));
        setSelectedState({ value: indiaStates[0].isoCode, label: indiaStates[0].name });
      } else {
        setStates([]);
        setCities([]);
      }
    };
    getStates();
  }, [selectedCountry]);

  useEffect(() => {
    const getCities = async () => {
      if (selectedState) {
        const indiaCities = await City.getCitiesOfState(selectedState.value);
        setCities(indiaCities.map((city) => ({ value: city.name, label: city.name })));
      }
    };
    getCities();
  }, [selectedState]);

  const handleCountryChange = (selected) => {
    setSelectedCountry(selected);
    setSelectedState(null);
    formik.setFieldValue("country", selected.label);
  };

  const handleStateChange = (selected) => {
    setSelectedState(selected);
    formik.setFieldValue("state", selected.label);
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      dob: "",
      companyName: "",
      companyDesc: "",
      foundationYear: "",
      city: "",
      state: "",
      country: "",
      gstNumber: "",
      companyAddress: "",
      latitude: "",
      longitude: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await dispatch(register_company(token, values, navigate));
    },
  });

  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place) {
      formik.setFieldValue("companyAddress", place.formatted_address);
      formik.setFieldValue("latitude", place.geometry.location.lat());
      formik.setFieldValue("longitude", place.geometry.location.lng());
    }
  };

  return (
    <div className="register-auth-wrapper">
      <div className="register-auth-inner">
        <h3>Company Details</h3>
        <form onSubmit={formik.handleSubmit}>
          <div className="register_row">
          <div className="register_column"> 
              <label className="form-label">Firstname</label>
              <input
                type="text"
                className="form-control"
                name="firstname"
                value={formik.values.firstname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.firstname && formik.errors.firstname ? (
                <div className="error">{formik.errors.firstname}</div>
              ) : null}
            </div>
            <div className="register_column">
              <label className="form-label">Lastname</label>
              <input
                type="text"
                className="form-control"
                name="lastname"
                value={formik.values.lastname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.lastname && formik.errors.lastname ? (
                <div className="error">{formik.errors.lastname}</div>
              ) : null}
            </div>
      
          </div>
          <div className="register_row">
            <div className="register_column">
              <label className="form-label">Date of birth</label>
              <input
                type="date"
                className="form-control"
                name="dob"
                value={formik.values.dob}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.dob && formik.errors.dob ? (
                <div className="error">{formik.errors.dob}</div>
              ) : null}
            </div>
            <div className="register_column">
              <label className="form-label">Company Name</label>
              <input
                type="text"
                className="form-control"
                name="companyName"
                value={formik.values.companyName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.companyName && formik.errors.companyName ? (
                <div className="error">{formik.errors.companyName}</div>
              ) : null}
            </div>
          </div>
          <div className="register_row">
            <div className="register_column">
              <label className="form-label">Company Description</label>
              <textarea
                rows="1"
                className="form-control"
                name="companyDesc"
                value={formik.values.companyDesc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}>
              </textarea>
              {formik.touched.companyDesc && formik.errors.companyDesc ? (
                <div className="error">{formik.errors.companyDesc}</div>
              ) : null}
            </div>
            <div className="register_column">
              <label className="form-label">Foundation Year</label>
              <input
                type="text"
                className="form-control"
                name="foundationYear"
                value={formik.values.foundationYear}
                onChange={formik.handleChange}
                onKeyDown={handleKeyDown}
                onBlur={formik.handleBlur}
              />
              {formik.touched.foundationYear && formik.errors.foundationYear ? (
                <div className="error">{formik.errors.foundationYear}</div>
              ) : null}
            </div>
          </div>
          <div className="register_row">
            <div className="register_column">
              <label className="form-label">Country</label>
              <Select
                options={countryList().getData().filter((country) => country.value === "IN")}
                value={selectedCountry}
                onChange={handleCountryChange}
              />
              {formik.touched.country && formik.errors.country && (
                <div className="error">{formik.errors.country}</div>
              )}
            </div>
            <div className="register_column">
              <label className="form-label">State</label>
              <Select options={states} value={selectedState} onChange={handleStateChange} />
              {formik.touched.state && formik.errors.state && (
                <div className="error">{formik.errors.state}</div>
              )}
            </div>
          </div>

          <div className="register_row">
            <div className="register_column">
              <label className="form-label">City</label>
              <input
                type="text"
                className="form-control"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onKeyDown={handleKeyDown}
                onBlur={formik.handleBlur}
              />
              {formik.touched.city && formik.errors.city && (
                <div className="error">{formik.errors.city}</div>
              )}
            </div>

            <div className="register_column">
              <label className="form-label">GST Number</label>
              <input
                type="text"
                className="form-control"
                name="gstNumber"
                value={formik.values.gstNumber}
                onChange={formik.handleChange}
                onKeyDown={handleKeyDown}
                onBlur={formik.handleBlur}
              />
              {formik.touched.gstNumber && formik.errors.gstNumber && (
                <div className="error">{formik.errors.gstNumber}</div>
              )}
            </div>
          </div>

          <div className="register_row">
            <div className="register_column">
              <label className="form-label">Company Address</label>
              {isLoaded ? (
                <Autocomplete
                  onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                  onPlaceChanged={onPlaceChanged}
                >
                  <input
                    type="text"
                    className="form-control w-75"
                    placeholder="Enter company address"
                    value={formik.values.companyAddress}
                    onChange={(e) => formik.setFieldValue("companyAddress", e.target.value)}
                  />
                </Autocomplete>
              ) : (
                <div>Loading address autocomplete...</div>
              )}
              {formik.touched.companyAddress && formik.errors.companyAddress && (
                <div className="error">{formik.errors.companyAddress}</div>
              )}
            </div>
          </div>

          <div className="button_container">
            <button type="submit" className="btn btn-primary">
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
