import React from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { FaCar, FaPlusCircle, FaShoppingCart, FaUsers, FaUserCircle, FaCalendar   } from 'react-icons/fa';
import './Dashboard.css';
import { Button } from 'react-bootstrap';

const NavItem = ({ to, text, icon }) => (
  <li className={''}>
      <Link to={to}>
        <span className="icon">{icon}</span>
        <span className="text">{text}</span>
      </Link>
  </li>
);

const navItems = [
  { to: '/admin/dashboard/booking', text: 'Booking', icon: <FaCalendar /> },
  { to: '/admin/dashboard/vehical', text: 'Vehicles List', icon: <FaCar /> },
  { to: '/admin/dashboard/add-new', text: 'Add Vehicles', icon: <FaPlusCircle /> },
  { to: '/admin/dashboard/products', text: 'Products', icon: <FaShoppingCart /> },
  { to: '/admin/dashboard/customers', text: 'Customers', icon: <FaUsers /> }
];

const Dashboard = () => {
  const navigate = useNavigate()

  const handleLogout =()=>{
    localStorage.clear()
    navigate("/")
  }

  return (
    <div className="dashboard-container">
      {/* Top Navigation Bar */}
      <div className="top-nav">
        <div className="logo">
          <span>Gadirent</span>
        </div>
        <ul className="nav-list">
          {navItems.map((item, index) => (
            <NavItem key={index} {...item} />
          ))}
        <li className={'dropdown'}>
        <Link>
          <span className="icon"> <FaUserCircle /></span>
          <span className="text">{"My Account"}</span>
        </Link>
        <div className="dropdown-content">
            <Button onClick={()=> navigate("/admin/dashboard/edit-profile")}>{'Profile'}</Button>
            <Link onClick={handleLogout}>{'Logout'}</Link>
        </div>
        </li>
        </ul>
      </div>

      {/* Main Content Outlet */}
      <div className="main-content">
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
