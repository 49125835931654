import { createSlice } from '@reduxjs/toolkit';
const initialState = {
	isLoading: false,
	error : false,
	errorMsg : "",
	successMsg : ""
};

const alertSlice = createSlice({
	name: 'alert',
	initialState: initialState,
	reducers: {
	  IsLoading(state, action) {
		state.isLoading= action.payload.data
	  },
      isError(state,action){
        state.error = action.payload.data
      },
      errorMsg(state,action){
		console.log("action",action.payload)
        state.errorMsg = action.payload.data
      },
	  successMsg(state,action){
        state.successMsg = action.payload.data
      }
      
	},
  });
  
  export const alertActions = alertSlice.actions;
  
  export default alertSlice;
